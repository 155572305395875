import React from 'react';
import educations from './educations.json'
import InfoPanel from './InfoPanel/InfoPanel';

const Educations = () => {

  return (
    <InfoPanel data={educations}/>
  );
};

export default Educations;