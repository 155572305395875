import React from 'react';
import projects from './projects.json'
import './Projects.scss'
import InfoPanel from '../InfoPanel/InfoPanel';

const Projects = () => {

  return (
    <InfoPanel data={projects}/>
  );
};

export default Projects;
