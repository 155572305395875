import React from 'react';
import './InfoPanel.scss'
import { useInView } from 'react-intersection-observer';

const InfoPanel = ({ data }) => {

  const { ref, inView } = useInView({
    threshold: 0.1, // Triggers when 10% of the element is in view
    triggerOnce: false, // Animation only happens once
  });

  return (
    <section id={`${data.title}`}>
      <div className='info-panel d-none d-lg-block text-light pt-3 pb-1 px-3 m-4'>
        <h2>{data.title}</h2>
        <ul>
          {data.subData.map((subDatum, index) => (
            <li key={index} className='info-subpanel ps-4 pe-3 pt-3 pb-1 my-3'>
              <h3>{subDatum.title}</h3>
              <p className='fs-5 text-light text-opacity-75'>{subDatum.summary}</p>
            </li>
          ))}
        </ul>
      </div>
      <div ref={ref} className={`info-panel ${inView ? 'in-view' : 'out-view'} d-lg-none d-block text-light pt-3 pb-1 px-3 m-4`}>
        <h2>{data.title}</h2>
        <ul>
          {data.subData.map((subDatum, index) => (
            <SubPanel key={index} subDatum={subDatum} />
          ))}
        </ul>
      </div>
    </section>
  );
};

const SubPanel = ({ subDatum }) => {
  const { ref, inView } = useInView({
    threshold: 0.99,
    triggerOnce: false,
  });

  return (
    <li ref={ref} className={`info-subpanel ${inView ? 'in-view' : 'out-view'} ps-4 pe-3 pt-3 pb-1 my-3`}>
      <h3>{subDatum.title}</h3>
      <p className='h6 text-light text-opacity-50 mb-1'>{subDatum.date}</p>
      <p className='fs-5 text-light text-opacity-75 mb-1'>{subDatum.summary}</p>
      <p className='text-light text-opacity-100 mb-1'>{subDatum.description}</p>
      {"skills" in subDatum ?
        <div className='row justify-content-center'>
          <p className='col-auto fs-4'>Skills</p>
          <div className='w-100'></div>
          {subDatum.skills.map((skill, index) => (
            index < 5 ?
              <div key={index} className="col-auto text-light text-opacity-75 mx-2 mb-3 border border-3 border-opacity border-blueAccent border-opacity-75 bg-black bg-opacity-10 py-1 px-2 text-center">
                {skill}
              </div>
              : null
          ))}
        </div>
        : null
      }
    </li>
  );
};

export default InfoPanel;
