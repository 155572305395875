import React from 'react';
import experience from './experience.json'
import './Experience.scss'
import InfoPanel from '../InfoPanel/InfoPanel';

const Experience = () => {

  return (
    <InfoPanel data={experience}/>
  );
};

export default Experience;
