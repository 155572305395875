// components/AboutMe.js

import React from 'react';
import { useInView } from 'react-intersection-observer';

const AboutMe = () => {

  const { ref, inView } = useInView({
    threshold: 0.9, // Triggers when 10% of the element is in view
    triggerOnce: false, // Animation only happens once
  });

  return (
    <section id="about-me">
      <div className='info-panel d-none d-lg-block text-light pt-3 pb-2 px-3 m-4'>
        <h1>About Me</h1>
        <h5>
          I'm currently a fourth year student at the University of Manchester completing a Masters in Artificial Intelligence. I've just finished the BSc portion of my Degree and achieved a first class qualification. Over the summmer I completed an internship at BTIG in London, learning a variety of skills and immersing myself into the work culture.
        </h5>
        </div>
        <div ref={ref} className={`info-panel ${inView ? 'in-view' : 'out-view'} d-lg-none d-block text-light pt-3 pb-2 px-3 m-4`}>
        <h1>About Me</h1>
        <h5>
          I'm currently a fourth year student at the University of Manchester completing a Masters in Artificial Intelligence. I've just finished the BSc portion of my Degree and achieved a first class qualification. Over the summmer I completed an internship at BTIG in London, learning a variety of skills and immersing myself into the work culture.
        </h5>
        </div>
    </section>
  );
};

export default AboutMe;
